#nav-mobile > li:last-child {
  margin-left: 50px;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.page-footer {
  padding-top: 0px;
}

span.badge {
  min-width: 6rem;
}

.ack-toggle {
  cursor: pointer;
}

@media only screen and (min-width: 993px) {
  #sidenav-button {
    display: none;
  }
}

@media only screen and (min-width: 601px) {
  #sidenav-button {
    height: 64px;
    line-height: 64px;
  }
}
